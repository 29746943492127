@import "variables";
@import "mixins";

.header {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(space-between);
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0;
  height: var(--header-height);

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-150%, -50%);
    z-index: 1;
    animation-name: logo;
    animation-duration: .6s;
    animation-fill-mode: forwards;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-delay: .6s;
  }
  .navbar {
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
    padding: 0;
    position: relative;

    .slider {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: relative;
      opacity: 0;
      animation-name: appearance;
      animation-duration: .3s;
      animation-fill-mode: forwards;
      .slide {
        span {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          color: transparent;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          opacity: 0;
          z-index: 0;
          animation: appearance .6s linear forwards 0s;

        }
        &.slide1 span {
          background-image: url("http://510434238.swh.strato-hosting.eu/projekt/hero.JPG");
        }
      }
    }
    .navbar-collapse {
      @include justify-content(center);
      @include flex-grow(0);
      width: 100%;
      height: 68px;
      background: var(--primary-color);
      z-index: 1;
      .navbar-nav {
        @include align-items(center);
        padding: .5rem 0;
      }
    }
  }
}


@media only screen and (max-height: 640px) {
  .header {
    max-height: var(--header-height-small-height-devices);
  }
}

@media only screen and (max-width: 767px) {
  .header {
    height: var(--header-height-small-devices);

    .navbar {
      padding: 0;
      width: 100%;
      .slider {
      }
      .navbar-collapse {
        @include flexbox;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        z-index: 1;
        background: var(--primary-color);
        position: fixed;
        top: 0;
        left: 0;
        width: 100% ;
        height: 100%;
        padding: var(--medium);
        opacity: 0;
        transition: all var(--transition-speed) ease-in-out .30s;
        visibility: hidden;

        .navbar-nav {
          @include align-items(flex-end);
          .nav-item > .navigation-link{
            opacity: 0;
            transition: all var(--transition-speed) ease-in-out, transform var(--transition-speed) ease-in-out var(--transition-speed);
            z-index: 1;
          }
        }
        &.show {
          opacity: 1;
          transition: all var(--transition-speed) ease-in-out;
          visibility: visible;
          .navbar-nav {
            &:before {
              opacity: 1;
              transition: all var(--transition-speed) ease-in-out;
            }
            .nav-item {

              @for $i from 1 through 20 {
                &:nth-child(#{$i}) > .navigation-link{
                  opacity: 1;
                  transition: opacity var(--transition-speed) ($i / 5) + .1s !important;
                }
              }
            }
          }
        }
      }
      .navbar-toggler {
        z-index: 2;
        border: none;
        padding: 0;
        order: 2;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 567px) {
  .header {
    .logo {
      img {
        width: 100px;
        height: auto;
      }
    }
  }
}



@media only screen and (max-height: 540px) {
  .header {
    .logo {
      img {
        width: 100px;
        height: auto;
      }
    }
  }
}
