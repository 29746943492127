@import 'variables';
@import "mixins";
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: 'Roboto Slab', serif;
  background-color: var(--tertiary-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  -webkit-text-fill-color: var(--secondary-color) !important;
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.contact-container {
  position: relative;
  height: 100%;
  transition: .6s ease-in-out;
  .contact-input {
    min-height: 40vh;

  }
  label {
    text-transform: uppercase;
  }
  .contact-message {
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-speed) ease-in-out;
    max-height: 0;
    width: auto;
    h1 {
      @include flex-basis(100%);
      display: inline-block;
    }
    span {
      padding-right: .25rem;
    }
    &.show-message {
      visibility: visible;
      max-height: 300px;
      opacity: 1;
      transition: var(--transition-speed) ease-in-out .15s, opacity var(--transition-speed) ease-in-out .30s;
    }
  }
  .contact-col {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(space-between);
  }
  .contact-error {
    visibility: hidden;
    opacity: 0;
    transition: var(--transition-speed) ease-in-out;
    color: var(--tertiary-color);
    font-weight: bolder;
    padding: var(--medium) 0;
    &.show-error {
      visibility: visible;
      opacity: 1;
      transition: var(--transition-speed) ease-in-out;
    }
  }
  form {
    visibility: visible;
    opacity: 1;
    transition: var(--transition-speed) ease-in-out 0s;
    max-height: 1500px;
    &.hide-form {
      visibility: hidden;
      opacity: 0;
      transition: var(--transition-speed) ease-in-out 0s;
      max-height: 0;
    }
    .btn {
      align-self: flex-start;
    }
  }
}

.leaflet-container {
  height: 400px;
}

.form-group{
  padding: .25rem 0;
  position: relative;
  color: var(--secondary-color);

  .form-group-span {
    transition: var(--transition-speed) ease-in-out;


  }
  &:focus-within {
    color: var(--tertiary-color);
    .form-group-span {
      color: var(--tertiary-color);
      input, textarea {
        color: var(--tertiary-color);
        border-color: var(--tertiary-color);
      }
    }
  }
  .form-control {
    &:focus {
      color: var(--secondary-color);
      transition: var(--transition-speed) ease-in-out;
      background: transparent;
      border-color: var(--secondary-color);
      box-shadow: none;
    }
  }
}


[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--secondary-color);
  border-radius: 100%;
  background: var(--primary-color);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--tertiary-color);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form-control + .form-control {
  margin-top: 1em;
}



@media only screen and (max-width: 767px) {
  .contact-container {
    .contact-input {
      min-height: 70vh;

    }
  }
}
