@import "variables";
@import "mixins";
.posts-grid {
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
  @include flex-wrap(wrap);
  position: relative;
  width: auto;

  .posts-grid-element {
    transition: var(--transition-speed);
    background-clip: padding-box;
    line-height: 0;
    min-height: calc( 100vh - var(--header-height));
    display: flex;
    padding: 0 5px ;
    flex-direction: column;
    justify-content: center;
    .image-box {
      display: block;
      position: relative;
      backface-visibility: hidden;
      overflow: hidden;
      cursor: pointer;
      margin: 5px 0;
      .grayscale {
        filter: grayscale(100%);
        transform: scale(1.15);
        transition: all var(--transition-speed) ease-in-out;
        overflow: hidden;
      }
      &:hover {
        .grayscale {
          filter: grayscale(0%);
          transform: scale(1);
          transition: all var(--transition-speed) ease-in-out;
          overflow: hidden;
        }
      }
      .headline {
        @include centerer;
        top: 50%; left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: var(--transition-speed);
        backface-visibility: hidden;
        background: var(--primary-color);
        padding: var(--small);
        z-index: 1;
        &:before {
          @include position(.25rem);
          z-index: -1;
          content: '';
          position: absolute;
          border: var(--border-width) solid var(--secondary-color);
        }
      }
      &:hover {
        backface-visibility: hidden;
        .headline {
          backface-visibility: hidden;
          transform: translate(-50%, -50%) translateZ(0) scale(1) perspective(1px);
          transition: var(--transition-speed);
          opacity: 1;
        }
      }
      &>img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-height: 640px) {
  .posts-grid {


    .posts-grid-element {
      min-height: calc(100vh - var(--header-height-small-height-devices));
    }
  }
}

@media only screen and (max-width: 767px) {
  .posts-grid {
    @include justify-content(center);
    margin-bottom: var(--medium);
    .posts-grid-element {

      .image-box {
        .grayscale {
          filter: grayscale(0%);
          transform: scale(1);
          transition: var(--transition-speed);
        }
        .headline {

          backface-visibility: hidden;
          opacity: 1;
          &:after {
            @include position(.25rem);
            content: '';
            position: absolute;
            border: 1px solid var(--secondary-color);
          }
        }

        & > img {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .posts-grid {
    .posts-grid-element {
      width: 100% !important;
     padding: 15px;
    }
  }
}
@media only screen and (max-width: 445px) {
  .posts-grid {
    .posts-grid-element {
      width: 100% !important;
    }
  }
}
