@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.loading span {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
  .loading span:nth-child(2) {
    -webkit-animation-delay: .3s;
            animation-delay: .3s;
    -webkit-animation-delay: var(--transition-speed);
            animation-delay: var(--transition-speed); }
  .loading span:nth-child(3) {
    -webkit-animation-delay: .3s;
            animation-delay: .3s;
    -webkit-animation-delay: var(--transition-speed);
            animation-delay: var(--transition-speed); }

@-webkit-keyframes slider-animation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  5% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    opacity: 1; }
  45% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes slider-animation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  5% {
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    opacity: 1; }
  45% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes appearance {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes appearance {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes menu-appearance {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes menu-appearance {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes shadow {
  from {
    background-color: rgba(0, 0, 0, 0); }
  to {
    background-color: rgba(0, 0, 0, 0.66); } }

@keyframes shadow {
  from {
    background-color: rgba(0, 0, 0, 0); }
  to {
    background-color: rgba(0, 0, 0, 0.66); } }

@-webkit-keyframes logo {
  0% {
    -webkit-transform: translate(-150%, -50%);
            transform: translate(-150%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 1; } }

@keyframes logo {
  0% {
    -webkit-transform: translate(-150%, -50%);
            transform: translate(-150%, -50%);
    opacity: 0; }
  100% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 1; } }

.content-container {
  position: relative; }
  .content-container p, .content-container h1, .content-container h2, .content-container h3, .content-container .modal-content-thumbnail img {
    position: relative; }

.fade-enter, .fade-appear {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all .3s ease-in-out .3s;
  transition: all var(--transition-speed) ease-in-out var(--transition-speed);
  opacity: 0; }
  .fade-enter .cat-container, .fade-appear .cat-container, .fade-enter .content-container, .fade-appear .content-container {
    opacity: 0;
    transition: .3s ease-in-out 0s;
    transition: var(--transition-speed) ease-in-out 0s; }
  .fade-enter .content-container, .fade-appear .content-container {
    top: 25px; }
    .fade-enter .content-container h1, .fade-appear .content-container h1, .fade-enter .content-container h2, .fade-appear .content-container h2, .fade-enter .content-container h3, .fade-appear .content-container h3 {
      top: 50px; }
    .fade-enter .content-container p, .fade-appear .content-container p {
      top: 75px; }
    .fade-enter .content-container .modal-content-thumbnail img, .fade-appear .content-container .modal-content-thumbnail img {
      left: -100px; }

.fade-enter-active, .fade-enter-done,
.fade-appear-done {
  opacity: 0;
  -webkit-transform: scale(1) translateX(0);
          transform: scale(1) translateX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all .3s ease-in-out;
  transition: all var(--transition-speed) ease-in-out; }

.fade-enter-done,
.fade-appear-done {
  opacity: 1;
  transition: all .3s ease-in-out;
  transition: all var(--transition-speed) ease-in-out; }
  .fade-enter-done .cat-container, .fade-enter-done .content-container,
  .fade-appear-done .cat-container,
  .fade-appear-done .content-container {
    opacity: 1;
    transition: .3s ease-in-out 0s;
    transition: var(--transition-speed) ease-in-out 0s; }
  .fade-enter-done .content-container,
  .fade-appear-done .content-container {
    top: 0;
    transition: .6s ease-in-out .6s;
    transition: var(--slow-transition-speed) ease-in-out var(--slow-transition-speed); }
    .fade-enter-done .content-container h1, .fade-enter-done .content-container h2, .fade-enter-done .content-container h3, .fade-enter-done .content-container p,
    .fade-appear-done .content-container h1,
    .fade-appear-done .content-container h2,
    .fade-appear-done .content-container h3,
    .fade-appear-done .content-container p {
      top: 0;
      transition: .6s ease-in-out .3s;
      transition: var(--slow-transition-speed) ease-in-out var(--transition-speed); }
    .fade-enter-done .content-container p,
    .fade-appear-done .content-container p {
      transition-delay: .6s;
      transition-delay: var(--slow-transition-speed); }
    .fade-enter-done .content-container .modal-content-thumbnail img,
    .fade-appear-done .content-container .modal-content-thumbnail img {
      left: 0; }

.fade-exit.fade-exit-active,
.fade-exit.fade-leave-active {
  opacity: 0;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all .3s ease-in-out;
  transition: all var(--transition-speed) ease-in-out; }

@font-face {
  font-family: 'The Star in the Midnight';
  src: url(/static/media/TheStarintheMidnight.5e048450.woff2) format("woff2"), url(/static/media/TheStarintheMidnight.d72102da.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

h1, h2, h3 {
  font-family: 'Work Sans', sans-serif;
  line-height: 1;
  margin: 0; }

h1 {
  font-family: 'The Star in the Midnight';
  font-size: 5rem;
  font-weight: 400;
  display: inline-block;
  position: relative; }

h2 {
  color: #bc405f;
  color: var(--tertiary-color);
  font-family: 'The Star in the Midnight';
  font-size: 4.25rem;
  display: inline-block;
  margin: .5rem 0px;
  position: relative; }
  h2:not(.post--title):not(.article--title) {
    font-family: 'Cormorant Garamond', serif;
    font-size: 2rem; }

h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.5rem;
  font-weight: 300; }

h4 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.25rem;
  font-weight: 300; }

p {
  font-family: 'Cormorant Garamond', serif;
  font-size: 1.125rem;
  line-height: 1.25; }

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 4.5rem; }
  h2 {
    font-size: 3.5rem;
    margin: .25rem 0px; }
  h3 {
    font-size: 1.5rem; } }

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 3.5rem; }
  h2 {
    font-size: 3rem; } }

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 3rem; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: 'Roboto Slab', serif;
  background-color: #bc405f !important;
  background-color: var(--tertiary-color) !important;
  border-color: #666 !important;
  border-color: var(--secondary-color) !important;
  color: #666 !important;
  color: var(--secondary-color) !important;
  -webkit-text-fill-color: #666 !important;
  -webkit-text-fill-color: var(--secondary-color) !important;
  -webkit-box-shadow: none !important;
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important; }

.contact-container {
  position: relative;
  height: 100%;
  transition: .6s ease-in-out; }
  .contact-container .contact-input {
    min-height: 40vh; }
  .contact-container label {
    text-transform: uppercase; }
  .contact-container .contact-message {
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
    transition: var(--transition-speed) ease-in-out;
    max-height: 0;
    width: auto; }
    .contact-container .contact-message h1 {
      flex-basis: 100%;
      display: inline-block; }
    .contact-container .contact-message span {
      padding-right: .25rem; }
    .contact-container .contact-message.show-message {
      visibility: visible;
      max-height: 300px;
      opacity: 1;
      transition: .3s ease-in-out 0.15s, opacity .3s ease-in-out 0.3s;
      transition: var(--transition-speed) ease-in-out 0.15s, opacity var(--transition-speed) ease-in-out 0.3s; }
  .contact-container .contact-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .contact-container .contact-error {
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
    transition: var(--transition-speed) ease-in-out;
    color: #bc405f;
    color: var(--tertiary-color);
    font-weight: bolder;
    padding: 2rem 0;
    padding: var(--medium) 0; }
    .contact-container .contact-error.show-error {
      visibility: visible;
      opacity: 1;
      transition: .3s ease-in-out;
      transition: var(--transition-speed) ease-in-out; }
  .contact-container form {
    visibility: visible;
    opacity: 1;
    transition: .3s ease-in-out 0s;
    transition: var(--transition-speed) ease-in-out 0s;
    max-height: 1500px; }
    .contact-container form.hide-form {
      visibility: hidden;
      opacity: 0;
      transition: .3s ease-in-out 0s;
      transition: var(--transition-speed) ease-in-out 0s;
      max-height: 0; }
    .contact-container form .btn {
      align-self: flex-start; }

.leaflet-container {
  height: 400px; }

.form-group {
  padding: .25rem 0;
  position: relative;
  color: #666;
  color: var(--secondary-color); }
  .form-group .form-group-span {
    transition: .3s ease-in-out;
    transition: var(--transition-speed) ease-in-out; }
  .form-group:focus-within {
    color: #bc405f;
    color: var(--tertiary-color); }
    .form-group:focus-within .form-group-span {
      color: #bc405f;
      color: var(--tertiary-color); }
      .form-group:focus-within .form-group-span input, .form-group:focus-within .form-group-span textarea {
        color: #bc405f;
        color: var(--tertiary-color);
        border-color: #bc405f;
        border-color: var(--tertiary-color); }
  .form-group .form-control:focus {
    color: #666;
    color: var(--secondary-color);
    transition: .3s ease-in-out;
    transition: var(--transition-speed) ease-in-out;
    background: transparent;
    border-color: #666;
    border-color: var(--secondary-color);
    box-shadow: none; }

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666; }

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #666;
  border: 1px solid var(--secondary-color);
  border-radius: 100%;
  background: #fff;
  background: var(--primary-color); }

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #bc405f;
  background: var(--tertiary-color);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  transition: all 0.2s ease; }

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.form-control + .form-control {
  margin-top: 1em; }

@media only screen and (max-width: 767px) {
  .contact-container .contact-input {
    min-height: 70vh; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 0;
  height: 62.5vh;
  height: var(--header-height); }
  .header .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-150%, -50%);
            transform: translate(-150%, -50%);
    z-index: 1;
    -webkit-animation-name: logo;
            animation-name: logo;
    -webkit-animation-duration: .6s;
            animation-duration: .6s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-delay: .6s;
            animation-delay: .6s; }
  .header .navbar {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    position: relative; }
    .header .navbar .slider {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: relative;
      opacity: 0;
      -webkit-animation-name: appearance;
              animation-name: appearance;
      -webkit-animation-duration: .3s;
              animation-duration: .3s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
      .header .navbar .slider .slide span {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        color: transparent;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        z-index: 0;
        -webkit-animation: appearance .6s linear forwards 0s;
                animation: appearance .6s linear forwards 0s; }
      .header .navbar .slider .slide.slide1 span {
        background-image: url("http://510434238.swh.strato-hosting.eu/projekt/hero.JPG"); }
    .header .navbar .navbar-collapse {
      justify-content: center;
      flex-grow: 0;
      width: 100%;
      height: 68px;
      background: #fff;
      background: var(--primary-color);
      z-index: 1; }
      .header .navbar .navbar-collapse .navbar-nav {
        align-items: center;
        padding: .5rem 0; }

@media only screen and (max-height: 640px) {
  .header {
    max-height: 60vh;
    max-height: var(--header-height-small-height-devices); } }

@media only screen and (max-width: 767px) {
  .header {
    height: 35vh;
    height: var(--header-height-small-devices); }
    .header .navbar {
      padding: 0;
      width: 100%; }
      .header .navbar .navbar-collapse {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background: #fff;
        background: var(--primary-color);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2rem;
        padding: var(--medium);
        opacity: 0;
        transition: all .3s ease-in-out 0.3s;
        transition: all var(--transition-speed) ease-in-out 0.3s;
        visibility: hidden; }
        .header .navbar .navbar-collapse .navbar-nav {
          align-items: flex-end; }
          .header .navbar .navbar-collapse .navbar-nav .nav-item > .navigation-link {
            opacity: 0;
            transition: all .3s ease-in-out, -webkit-transform .3s ease-in-out .3s;
            transition: all .3s ease-in-out, transform .3s ease-in-out .3s;
            transition: all .3s ease-in-out, transform .3s ease-in-out .3s, -webkit-transform .3s ease-in-out .3s;
            transition: all var(--transition-speed) ease-in-out, -webkit-transform var(--transition-speed) ease-in-out var(--transition-speed);
            transition: all var(--transition-speed) ease-in-out, transform var(--transition-speed) ease-in-out var(--transition-speed);
            transition: all var(--transition-speed) ease-in-out, transform var(--transition-speed) ease-in-out var(--transition-speed), -webkit-transform var(--transition-speed) ease-in-out var(--transition-speed);
            z-index: 1; }
        .header .navbar .navbar-collapse.show {
          opacity: 1;
          transition: all .3s ease-in-out;
          transition: all var(--transition-speed) ease-in-out;
          visibility: visible; }
          .header .navbar .navbar-collapse.show .navbar-nav:before {
            opacity: 1;
            transition: all .3s ease-in-out;
            transition: all var(--transition-speed) ease-in-out; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(1) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 0.3s !important;
            transition: opacity var(--transition-speed) 0.3s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(2) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 0.5s !important;
            transition: opacity var(--transition-speed) 0.5s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(3) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 0.7s !important;
            transition: opacity var(--transition-speed) 0.7s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(4) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 0.9s !important;
            transition: opacity var(--transition-speed) 0.9s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(5) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 1.1s !important;
            transition: opacity var(--transition-speed) 1.1s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(6) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 1.3s !important;
            transition: opacity var(--transition-speed) 1.3s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(7) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 1.5s !important;
            transition: opacity var(--transition-speed) 1.5s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(8) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 1.7s !important;
            transition: opacity var(--transition-speed) 1.7s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(9) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 1.9s !important;
            transition: opacity var(--transition-speed) 1.9s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(10) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 2.1s !important;
            transition: opacity var(--transition-speed) 2.1s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(11) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 2.3s !important;
            transition: opacity var(--transition-speed) 2.3s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(12) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 2.5s !important;
            transition: opacity var(--transition-speed) 2.5s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(13) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 2.7s !important;
            transition: opacity var(--transition-speed) 2.7s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(14) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 2.9s !important;
            transition: opacity var(--transition-speed) 2.9s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(15) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 3.1s !important;
            transition: opacity var(--transition-speed) 3.1s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(16) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 3.3s !important;
            transition: opacity var(--transition-speed) 3.3s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(17) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 3.5s !important;
            transition: opacity var(--transition-speed) 3.5s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(18) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 3.7s !important;
            transition: opacity var(--transition-speed) 3.7s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(19) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 3.9s !important;
            transition: opacity var(--transition-speed) 3.9s !important; }
          .header .navbar .navbar-collapse.show .navbar-nav .nav-item:nth-child(20) > .navigation-link {
            opacity: 1;
            transition: opacity .3s 4.1s !important;
            transition: opacity var(--transition-speed) 4.1s !important; }
      .header .navbar .navbar-toggler {
        z-index: 2;
        border: none;
        padding: 0;
        order: 2; }
        .header .navbar .navbar-toggler:focus {
          outline: none; } }

@media only screen and (max-width: 567px) {
  .header .logo img {
    width: 100px;
    height: auto; } }

@media only screen and (max-height: 540px) {
  .header .logo img {
    width: 100px;
    height: auto; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.navbar-toggler {
  position: absolute;
  right: 1rem;
  right: var(--small);
  top: .25rem;
  width: 30px;
  height: 50px;
  border: none !important;
  padding: 0 !important; }
  .navbar-toggler .line {
    width: 100%;
    float: left;
    height: 3px;
    background-color: #666;
    background-color: var(--secondary-color);
    margin-bottom: 8px; }
    .navbar-toggler .line:nth-child(2) {
      -webkit-transform: translate(-200%);
              transform: translate(-200%);
      transition: .3s;
      transition: var(--transition-speed);
      opacity: 0; }
    .navbar-toggler .line:nth-child(1) {
      -webkit-transform: rotate(45deg) translate(9px, 6px);
              transform: rotate(45deg) translate(9px, 6px);
      transition: .3s;
      transition: var(--transition-speed); }
    .navbar-toggler .line:nth-child(3) {
      -webkit-transform: rotate(-45deg) translate(9px, -6px);
              transform: rotate(-45deg) translate(9px, -6px);
      transition: .3s;
      transition: var(--transition-speed);
      background-color: #bc405f;
      background-color: var(--tertiary-color); }
  .navbar-toggler.collapsed .line {
    background-color: #fff;
    background-color: var(--primary-color); }
    .navbar-toggler.collapsed .line:nth-child(2) {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      transition: .3s;
      transition: var(--transition-speed);
      opacity: 1; }
    .navbar-toggler.collapsed .line:nth-child(1) {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      transition: .3s;
      transition: var(--transition-speed); }
    .navbar-toggler.collapsed .line:nth-child(3) {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      transition: .3s;
      transition: var(--transition-speed);
      background-color: #fff;
      background-color: var(--primary-color); }

.nav-item:not(.navigation-link) {
  margin: 0 calc(1rem / 2);
  margin: 0 calc(var(--small) / 2);
  opacity: 0;
  -webkit-animation-name: menu-appearance;
          animation-name: menu-appearance;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-duration: var(--transition-speed);
          animation-duration: var(--transition-speed);
  -webkit-animation-delay: .3s;
          animation-delay: .3s;
  -webkit-animation-delay: var(--transition-speed);
          animation-delay: var(--transition-speed);
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out; }

.nav-item .nav-link, .nav-item .navigation-link {
  position: relative;
  color: #666;
  color: var(--secondary-color);
  padding: 0 calc(1rem / 2);
  padding: 0 calc(var(--small) / 2);
  font-family: 'Work Sans', sans-serif;
  font-family: var(--font-family);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 300;
  transition: all .3s ease-in-out 0s;
  transition: all var(--transition-speed) ease-in-out 0s; }

.nav-item .nav-link {
  color: #666 !important;
  color: var(--secondary-color) !important; }

.nav-item .navigation-link {
  text-align: right; }
  .nav-item .navigation-link.active {
    pointer-events: none; }
  .nav-item .navigation-link:hover {
    transition: all .3s ease-in-out 0s;
    transition: all var(--transition-speed) ease-in-out 0s; }

.dropdown-menu {
  border: none;
  right: 0;
  left: unset;
  border-left: 1px solid #bc405f;
  border-left: 1px solid var(--tertiary-color);
  border-radius: 0;
  opacity: 0;
  transition: all .3s;
  transition: all var(--transition-speed);
  z-index: -1; }
  .dropdown-menu .dropdown-item {
    visibility: hidden;
    background: transparent;
    text-align: right;
    padding: 0;
    opacity: 0;
    transition: all .3s;
    transition: all var(--transition-speed); }
    .dropdown-menu .dropdown-item:nth-of-type(1) {
      transition-delay: 0.3s; }
    .dropdown-menu .dropdown-item:nth-of-type(2) {
      transition-delay: 0.6s; }
    .dropdown-menu .dropdown-item:nth-of-type(3) {
      transition-delay: 0.9s; }
    .dropdown-menu .dropdown-item:nth-of-type(4) {
      transition-delay: 1.2s; }
    .dropdown-menu .dropdown-item:nth-of-type(5) {
      transition-delay: 1.5s; }
    .dropdown-menu .dropdown-item:nth-of-type(6) {
      transition-delay: 1.8s; }
    .dropdown-menu .dropdown-item:nth-of-type(7) {
      transition-delay: 2.1s; }
    .dropdown-menu .dropdown-item:nth-of-type(8) {
      transition-delay: 2.4s; }
    .dropdown-menu .dropdown-item:nth-of-type(9) {
      transition-delay: 2.7s; }
    .dropdown-menu .dropdown-item:nth-of-type(10) {
      transition-delay: 3s; }
    .dropdown-menu .dropdown-item:nth-of-type(11) {
      transition-delay: 3.3s; }
    .dropdown-menu .dropdown-item:nth-of-type(12) {
      transition-delay: 3.6s; }
    .dropdown-menu .dropdown-item:nth-of-type(13) {
      transition-delay: 3.9s; }
    .dropdown-menu .dropdown-item:nth-of-type(14) {
      transition-delay: 4.2s; }
    .dropdown-menu .dropdown-item:nth-of-type(15) {
      transition-delay: 4.5s; }
    .dropdown-menu .dropdown-item:nth-of-type(16) {
      transition-delay: 4.8s; }
    .dropdown-menu .dropdown-item:nth-of-type(17) {
      transition-delay: 5.1s; }
    .dropdown-menu .dropdown-item:nth-of-type(18) {
      transition-delay: 5.4s; }
    .dropdown-menu .dropdown-item:nth-of-type(19) {
      transition-delay: 5.7s; }
    .dropdown-menu .dropdown-item:nth-of-type(20) {
      transition-delay: 6s; }
  .dropdown-menu.show {
    opacity: 1;
    transition: all .3s ease-in .3s;
    transition: all var(--transition-speed) ease-in var(--transition-speed);
    -webkit-transform: translateY(0);
            transform: translateY(0); }
    .dropdown-menu.show .dropdown-item {
      opacity: 1;
      transition: all .3s .3s;
      transition: all var(--transition-speed) var(--transition-speed);
      visibility: visible; }
      .dropdown-menu.show .dropdown-item:nth-of-type(1) {
        transition-delay: 0.2s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(2) {
        transition-delay: 0.4s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(3) {
        transition-delay: 0.6s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(4) {
        transition-delay: 0.8s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(5) {
        transition-delay: 1s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(6) {
        transition-delay: 1.2s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(7) {
        transition-delay: 1.4s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(8) {
        transition-delay: 1.6s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(9) {
        transition-delay: 1.8s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(10) {
        transition-delay: 2s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(11) {
        transition-delay: 2.2s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(12) {
        transition-delay: 2.4s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(13) {
        transition-delay: 2.6s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(14) {
        transition-delay: 2.8s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(15) {
        transition-delay: 3s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(16) {
        transition-delay: 3.2s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(17) {
        transition-delay: 3.4s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(18) {
        transition-delay: 3.6s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(19) {
        transition-delay: 3.8s; }
      .dropdown-menu.show .dropdown-item:nth-of-type(20) {
        transition-delay: 4s; }

@media only screen and (max-width: 767px) {
  .nav-item .nav-link, .nav-item .navigation-link {
    padding: calc(1rem / 4) calc(1rem / 2);
    padding: calc(var(--small) / 4) calc(var(--small) / 2);
    font-size: 1.25rem; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.posts-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  width: auto; }
  .posts-grid .posts-grid-element {
    transition: .3s;
    transition: var(--transition-speed);
    background-clip: padding-box;
    line-height: 0;
    min-height: calc( 100vh - 62.5vh);
    min-height: calc( 100vh - var(--header-height));
    display: flex;
    padding: 0 5px;
    flex-direction: column;
    justify-content: center; }
    .posts-grid .posts-grid-element .image-box {
      display: block;
      position: relative;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      overflow: hidden;
      cursor: pointer;
      margin: 5px 0; }
      .posts-grid .posts-grid-element .image-box .grayscale {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        -webkit-transform: scale(1.15);
                transform: scale(1.15);
        transition: all .3s ease-in-out;
        transition: all var(--transition-speed) ease-in-out;
        overflow: hidden; }
      .posts-grid .posts-grid-element .image-box:hover .grayscale {
        -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
        -webkit-transform: scale(1);
                transform: scale(1);
        transition: all .3s ease-in-out;
        transition: all var(--transition-speed) ease-in-out;
        overflow: hidden; }
      .posts-grid .posts-grid-element .image-box .headline {
        position: absolute;
        top: 50%;
        left: 50%;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        transition: .3s;
        transition: var(--transition-speed);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        background: #fff;
        background: var(--primary-color);
        padding: 1rem;
        padding: var(--small);
        z-index: 1; }
        .posts-grid .posts-grid-element .image-box .headline:before {
          top: 0.25rem;
          right: 0.25rem;
          bottom: 0.25rem;
          left: 0.25rem;
          position: absolute;
          z-index: -1;
          content: '';
          position: absolute;
          border: 1px solid #666;
          border: var(--border-width) solid var(--secondary-color); }
      .posts-grid .posts-grid-element .image-box:hover {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden; }
        .posts-grid .posts-grid-element .image-box:hover .headline {
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
          -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1) perspective(1px);
                  transform: translate(-50%, -50%) translateZ(0) scale(1) perspective(1px);
          transition: .3s;
          transition: var(--transition-speed);
          opacity: 1; }
      .posts-grid .posts-grid-element .image-box > img {
        width: 100%; }

@media only screen and (max-height: 640px) {
  .posts-grid .posts-grid-element {
    min-height: calc(100vh - 60vh);
    min-height: calc(100vh - var(--header-height-small-height-devices)); } }

@media only screen and (max-width: 767px) {
  .posts-grid {
    justify-content: center;
    margin-bottom: 2rem;
    margin-bottom: var(--medium); }
    .posts-grid .posts-grid-element .image-box .grayscale {
      -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
      -webkit-transform: scale(1);
              transform: scale(1);
      transition: .3s;
      transition: var(--transition-speed); }
    .posts-grid .posts-grid-element .image-box .headline {
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      opacity: 1; }
      .posts-grid .posts-grid-element .image-box .headline:after {
        top: 0.25rem;
        right: 0.25rem;
        bottom: 0.25rem;
        left: 0.25rem;
        position: absolute;
        content: '';
        position: absolute;
        border: 1px solid #666;
        border: 1px solid var(--secondary-color); }
    .posts-grid .posts-grid-element .image-box > img {
      width: 100%; } }

@media only screen and (max-width: 576px) {
  .posts-grid .posts-grid-element {
    width: 100% !important;
    padding: 15px; } }

@media only screen and (max-width: 445px) {
  .posts-grid .posts-grid-element {
    width: 100% !important; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.post .modal-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 62.5vh);
  height: calc(100vh - var(--header-height));
  padding: 0; }
  .post .modal-content-header .modal-content-thumbnail img {
    max-width: 100%;
    z-index: -1; }
  .post .modal-content-header .modal-content-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }

.post .modal-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem 0;
  padding: var(--big) 0; }
  .post .modal-content-body p {
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0;
    padding: var(--medium) 0; }
  .post .modal-content-body .wp-block-gallery .blocks-gallery-item {
    z-index: 2; }
  .post .modal-content-body .board .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    --primary-color-space: nowrap; }
    .post .modal-content-body .board .row .board-image {
      padding: 0; }
      .post .modal-content-body .board .row .board-image img {
        width: 100%; }

@media only screen and (max-width: 989px) {
  .post .modal-content-header {
    height: auto;
    min-height: 40vh;
    align-items: center; } }

@media only screen and (max-height: 640px) {
  .post .modal-content-header {
    min-height: calc(100vh - 60vh);
    min-height: calc(100vh - var(--header-height-small-height-devices)); } }

@media only screen and (max-width: 576px) {
  .post .modal-content-header {
    justify-content: center;
    align-items: flex-start;
    height: auto;
    min-height: calc( 100vh - 35vh);
    min-height: calc( 100vh - var(--header-height-small-devices));
    position: relative;
    margin-top: 15px; }
  .post .modal-content-body p {
    padding: 2rem 0;
    padding: var(--medium) 0; } }

@media only screen and (max-width: 480px) {
  .post .modal-content-header .modal-content-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .post .modal-content-header .modal-content-text {
    align-items: center;
    text-align: center; }
  .post .modal-content-body figure img {
    width: auto; }
  .post .modal-content-body p {
    margin-left: auto;
    margin-right: auto;
    width: 100%; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.article {
  min-height: 40vh; }
  .article .modal-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0; }
    .article .modal-content-header .modal-content-thumbnail img {
      max-width: 100%;
      z-index: -1; }
    .article .modal-content-header .modal-content-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end; }
  .article .modal-content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0;
    padding: var(--big) 0; }
    .article .modal-content-body p {
      margin-left: auto;
      margin-right: auto;
      padding: 2rem 0;
      padding: var(--medium) 0; }
    .article .modal-content-body .wp-block-gallery .blocks-gallery-item {
      z-index: 2; }
    .article .modal-content-body .board .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      --primary-color-space: nowrap; }
      .article .modal-content-body .board .row .board-image {
        padding: 0; }
        .article .modal-content-body .board .row .board-image img {
          width: 100%; }

@media only screen and (max-width: 989px) {
  .article .modal-content-header {
    height: auto;
    align-items: center; } }

@media only screen and (max-width: 767px) {
  .article .modal-content-header {
    justify-content: center;
    align-items: flex-start;
    height: auto;
    position: relative;
    margin-top: 15px; }
  .article .modal-content-body p {
    padding: 2rem 0;
    padding: var(--medium) 0; } }

@media only screen and (max-width: 480px) {
  .article .modal-content-header .modal-content-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .article .modal-content-header .modal-content-text {
    align-items: center;
    text-align: center; }
  .article .modal-content-body figure img {
    width: auto; }
  .article .modal-content-body p {
    margin-left: auto;
    margin-right: auto;
    width: 100%; } }

:root {
  --primary-color: #fff;
  --secondary-color: #666;
  --tertiary-color: #bc405f;
  --small: 1rem;
  --medium: 2rem;
  --big: 3rem;
  --header-height: 62.5vh;
  --header-height-small-height-devices: 60vh;
  --header-height-small-devices: 35vh;
  --transition-speed: .3s;
  --slow-transition-speed: .6s;
  --border-width: 1px;
  --font-family: 'Work Sans', sans-serif; }

.footer {
  background-color: #fff;
  background-color: var(--primary-color);
  text-align: center; }
  .footer .nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .footer .nav .nav-item a {
      color: #666;
      color: var(--secondary-color);
      padding: 0.5rem;
      font-family: 'Work Sans', sans-serif;
      font-family: var(--font-family);
      font-size: 1rem;
      font-weight: 300; }
      .footer .nav .nav-item a:hover, .footer .nav .nav-item a.active {
        color: #bc405f;
        color: var(--tertiary-color);
        text-decoration: underline; }

@media only screen and (max-width: 767px) {
  .footer {
    justify-content: flex-start; } }

#root .body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.welcome-message {
  min-height: calc(100vh - 62.5vh);
  min-height: calc(100vh - var(--header-height));
  text-align: center; }

.transition-elementabsolute {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute; }
  .transition-elementabsolute.minusindex {
    z-index: -1; }

body {
  color: #666;
  color: var(--secondary-color);
  font-family: 'Work Sans', sans-serif;
  font-family: var(--font-family);
  font-weight: 300; }
  body.overflowhidden {
    overflow: hidden; }
    @media only screen and (min-width: 768px) {
      body.overflowhidden {
        padding-right: 6px; } }

* {
  scrollbar-width: thin;
  scrollbar-color: #666 --primary-color;
  scrollbar-color: var(--secondary-color) --primary-color; }

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px; }

*::-webkit-scrollbar-track {
  background: #666;
  background: var(--secondary-color); }

*::-webkit-scrollbar-thumb {
  background-color: #bc405f;
  background-color: var(--tertiary-color);
  border-radius: 0; }

.form-control, .btn {
  border: 1px solid #666;
  border: var(--border-width) solid var(--secondary-color);
  background: transparent;
  color: #666;
  color: var(--secondary-color);
  border-radius: 0px; }

a:not(.logo):not(.css-rdzgic) {
  position: relative;
  display: inline-block; }
  a:not(.logo):not(.css-rdzgic):hover, a:not(.logo):not(.css-rdzgic).active {
    color: #bc405f !important;
    color: var(--tertiary-color) !important; }

.btn {
  font-family: 'Work Sans', sans-serif;
  font-family: var(--font-family);
  transition: .3s ease-in-out;
  transition: var(--transition-speed) ease-in-out;
  position: relative; }
  .btn:focus {
    outline: none;
    box-shadow: none; }
  .btn.active, .btn:focus, .btn:active {
    transition: .3s ease-in-out;
    transition: var(--transition-speed) ease-in-out;
    background: transparent;
    color: #bc405f;
    color: var(--tertiary-color);
    border-color: #bc405f;
    border-color: var(--tertiary-color); }

.content-container {
  min-height: 50vh; }

.cat-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%; }
  .cat-container button {
    padding: calc( 1rem / 2);
    padding: calc( var(--small) / 2);
    margin: calc( 1rem / 2);
    margin: calc( var(--small) / 2); }

a {
  color: #666;
  color: var(--secondary-color);
  text-decoration: none; }
  a:hover {
    color: #666;
    color: var(--secondary-color);
    text-decoration: none; }

ul {
  list-style: none;
  padding: 0; }

.required-error {
  color: #bc405f;
  color: var(--tertiary-color); }

figure, ul {
  margin: 0; }

ul.errors {
  display: flex;
  flex-direction: column; }

.modal-backdrop {
  display: none; }

.transition-element > * {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  min-height: 1px;
  width: 100%; }

@media only screen and (max-height: 640px) {
  .welcome-message {
    min-height: calc(100vh - 60vh);
    min-height: calc(100vh - var(--header-height-small-height-devices)); } }

@media only screen and (max-width: 767px) {
  .welcome-message {
    min-height: calc( 100vh - 35vh);
    min-height: calc( 100vh - var(--header-height-small-devices)); } }

