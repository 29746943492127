@import "variables";

.loading {
  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
      animation-delay: var(--transition-speed);
    }
    &:nth-child(3) {
      animation-delay: var(--transition-speed);
    }
  }
}

@keyframes slider-animation {
  0% { opacity: 0; animation-timing-function: ease-in; }
  5% { opacity: 1; animation-timing-function: ease-out; }
  40% { opacity: 1 }
  45% { opacity: 0 }
  100% { opacity: 0 }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menu-appearance {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);

  }
}

@keyframes shadow {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,.66);
  }
}

@keyframes logo {
  0% {
    transform: translate(-150%, -50%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
