@import 'variables';


.navbar-toggler {
  position: absolute;
  right: var(--small);
  top: .25rem;
  width: 30px;
  height: 50px;
  border: none !important;
  padding: 0 !important;
  .line{
    width: 100%;
    float: left;
    height: 3px;
    background-color: var(--secondary-color);
    margin-bottom: 8px;
    &:nth-child(2) {
      transform: translate(-200%);
      transition: var(--transition-speed);
      opacity: 0;
    }
    &:nth-child(1) {
      transform: rotate(45deg) translate(9px, 6px);
      transition: var(--transition-speed);
    }
    &:nth-child(3) {
      transform: rotate(-45deg) translate(9px,-6px);
      transition: var(--transition-speed);
      background-color: var(--tertiary-color);
    }
  }
  &.collapsed {
    .line{
      background-color: var(--primary-color);
      &:nth-child(2) {
        transform: translateX(0);
        transition: var(--transition-speed);
        opacity: 1;
      }
      &:nth-child(1) {
        transform: rotate(0);
        transition: var(--transition-speed);
      }
      &:nth-child(3) {
        transform: rotate(0);
        transition: var(--transition-speed);
        background-color: var(--primary-color);
      }
    }
  }
}
.nav-item {

  &:not(.navigation-link) {
    margin: 0 calc(var(--small) / 2);
    opacity: 0;
    animation-name: menu-appearance;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-duration: var(--transition-speed);
    animation-delay: var(--transition-speed);
    animation-timing-function: ease-in-out;
  }
  .nav-link, .navigation-link  {
    position: relative;

    color: var(--secondary-color);
    padding: 0 calc(var(--small) / 2);
    font-family: var(--font-family);
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    transition: all var(--transition-speed) ease-in-out 0s;

  }
  .nav-link {
    color: var(--secondary-color) !important;
  }
  .navigation-link {
    text-align: right;

    &.active {
      pointer-events: none;
    }
    &:hover {
      //color: $tertiary-color;
      transition: all var(--transition-speed) ease-in-out 0s;
    }
  }
}

.dropdown-menu {
  border: none;
  right: 0;
  left: unset;
  border-left: 1px solid var(--tertiary-color);
  border-radius: 0;
  opacity: 0;
  transition: all var(--transition-speed);
  z-index: -1;
  .dropdown-item {
    visibility: hidden;

    background: transparent;
    text-align: right;
    padding: 0;
    opacity: 0;
    transition: all var(--transition-speed);
    @for $l from 1 through 20 {
      &:nth-of-type(#{$l}) {
        transition-delay: .3s*$l;
      }
    }
  }
  &.show {
    opacity: 1;
    transition: all var(--transition-speed) ease-in var(--transition-speed);
    transform: translateY(0);
    .dropdown-item {
      opacity: 1;
      transition: all var(--transition-speed) var(--transition-speed);
      visibility: visible;

      @for $l from 1 through 20 {
        &:nth-of-type(#{$l}) {
          transition-delay: .2s*$l;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .nav-item {
    &:not(.navigation-link) {

    }
    .nav-link, .navigation-link  {
      padding: calc(var(--small) / 4) calc(var(--small) / 2);
      font-size: 1.25rem;
    }
  }

}
