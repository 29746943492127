@font-face {
  font-family: 'The Star in the Midnight';
  src: url('../fonts/TheStarintheMidnight.woff2') format('woff2'),
  url('../fonts/TheStarintheMidnight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
h1,h2,h3 {
  font-family: 'Work Sans', sans-serif;
  line-height: 1;
  margin: 0;
}

h1 {
  font-family: 'The Star in the Midnight';

  font-size: 5rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

h2 {
  color: var(--tertiary-color);
  font-family: 'The Star in the Midnight';

  font-size: 4.25rem;
  display: inline-block;
  margin: .5rem 0px;
  position: relative;
  &:not(.post--title):not(.article--title) {
    font-family: 'Cormorant Garamond', serif;
    font-size: 2rem;
  }
}

  h3 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.5rem;
    font-weight: 300;
  }

  h4 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.25rem;
    font-weight: 300;
  }

  p {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.125rem;
    line-height: 1.25;
  }

  @media only screen and (max-width: 1024px) {
    h1 {
      font-size: 4.5rem;
    }

    h2 {

      font-size: 3.5rem;
      margin: .25rem 0px;

    }


    h3 {
      font-size: 1.5rem;
    }

  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      font-size: 3rem;
    }
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 3rem;
    }
  }
