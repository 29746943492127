@import "variables";
@import "mixins";
.footer {
  background-color: var(--primary-color);
  text-align: center;
  .nav {
    @include flexbox;
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(center);
    .nav-item {
      a {
        color: var(--secondary-color);
        padding: 0.5rem;
        font-family: var(--font-family);

        font-size: 1rem;
        font-weight: 300;
        &:hover, &.active {
          color: var(--tertiary-color);
          text-decoration: underline;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    @include justify-content(flex-start);

  }
}
